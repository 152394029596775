<!--========================
Author by: Sreng Veasna
Created on: 15.09.2020
Modified:
Company: F2C V2
Description:
=========================-->
<template>
  <div>
    <v-card outlined max-width="900px" class="mx-auto my-4">
      <v-card-title>Create Listener</v-card-title>

      <v-card-text>
        <v-alert
          v-show="isShowAlert"
          text
          :type="isSuccess ? 'success' : 'error'"
        >{{ alertmessage }}</v-alert
        >
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              label="Platform"
              :items="platforms"
              persistent-placeholder
              hide-details
              item-value="idplatform"
              v-model="listener.idplatform"
            >
              <template v-slot:selection="{ item }">
                {{item.name}}
              </template>
              <template v-slot:item="{ item }">
                {{item.name}}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="6">
            <input-text-field
              :filled="true"
              label="Display Name"
              v-model="listener.displayname"
              :placeholder="'Entry you text'"
            ></input-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <input-text-field
              :filled="true"
              label="Queue Name"
              v-model="listener.queuename"
              :placeholder="'Entry you text'"
            ></input-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <input-text-field
              :filled="true"
              label="Entity"
              v-model="listener.entity"
              :placeholder="'Entry you text'"
            ></input-text-field>
          </v-col>
          <v-col cols="12">
            <input-text-field
              :filled="true"
              label="Description"
              v-model="listener.description"
              :placeholder="'Entry you text'"
            ></input-text-field>
          </v-col>
        </v-row>

        <div class="mt-8">
          <div class="d-flex align-center mb-2">
            <label class="black--text text-subtitle-1 my-0 mr-4">Method</label>
            <v-btn
              text
              color="primary"
              class="text-caption font-weight-bold"
              @click="showAddNewMethodModal = true"
            >
              Add Method
            </v-btn>
          </div>
          <v-row align="center">
            <v-col cols="11">
              <v-select
                outlined
                :items="methodOptions"
                v-model="selectedMethodKey"
                :placeholder="
                  methods.length == 0 ? 'No method available' : null
                "
                :disabled="methods.length == 0"
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="1">
              <v-btn
                icon
                :disabled="!selectedMethodKey"
                class="text-caption font-weight-bold"
                @click="removeMethod"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <div v-if="selectedMethod" class="mt-2">
          <v-row>
            <v-col cols="12" lg="6">
              <input-text-field
                :filled="true"
                label="Display Name"
                v-model="selectedMethod.description"
                :placeholder="'Entry you text'"
              ></input-text-field>
            </v-col>
            <v-col cols-="12" lg="6">
              <input-text-field
                :filled="true"
                label="Technical Name"
                v-model="selectedMethod.technicalname"
                :placeholder="'Entry you text'"
              ></input-text-field>
            </v-col>
            <v-col cols="12">
              <input-text-field
                :filled="true"
                label="Description Name"
                v-model="selectedMethod.description"
                :placeholder="'Entry you text'"
              ></input-text-field>
            </v-col>
          </v-row>

          <div class="mt-8">
            <label class="text-subtitle-1 mb-4">Parameter Documentation</label>
            <v-row v-if="!hasParameter" no-gutters>
              <v-col cols="4">
                <v-btn
                  height="50px"
                  block
                  text
                  tile
                  :ripple="false"
                  class="text-caption font-weight-bold"
                  color="grey darken-1"
                  @click="addDocumentParameter('context_data_parameter')"
                >
                  <v-icon left color="primary">mdi-plus-circle</v-icon>Add Context Data
                  Parameter</v-btn
                >
              </v-col>
              <v-col cols="4">
                <v-btn
                  height="50px"
                  block
                  text
                  tile
                  :ripple="false"
                  class="text-caption font-weight-bold"
                  color="grey darken-1"
                  @click="addDocumentParameter('matching_parameter')"
                >
                  <v-icon left color="primary">mdi-plus-circle</v-icon>Add Matching
                  Parameter</v-btn
                >
              </v-col>
              <v-col cols="4">
                <v-btn
                  height="50px"
                  block
                  text
                  tile
                  :ripple="false"
                  class="text-caption font-weight-bold"
                  color="grey darken-1"
                  @click="addDocumentParameter('step_parameter')"
                ><v-icon left color="primary">mdi-plus-circle</v-icon>Add Step
                  Parameter</v-btn
                >
              </v-col>
            </v-row>
            <div v-else>
              <v-tabs fixed-tabs v-model="selectedMethod.parameter_documentation.selectedTab">
                <v-tab
                  class="text-caption font-weight-bold"
                  :ripple="false"
                  @click="
                    addDocumentParameterIfNeeded('context_data_parameter')
                  "
                >
                  <div v-if="!contextDataParameter">
                    <v-icon left small color="primary">mdi-plus-circle</v-icon>Add Context Data
                    Parameter
                  </div>
                  <div v-else>Context Data Parameter</div>
                </v-tab>
                <v-tab
                  class="text-caption font-weight-bold"
                  :ripple="false"
                  @click="addDocumentParameterIfNeeded('matching_parameter')"
                >
                  <div v-if="!matchingParameter">
                    <v-icon left small color="primary">mdi-plus-circle</v-icon>Add Matching
                    Parameter
                  </div>
                  <div v-else>Matching Parameter</div>
                </v-tab>
                <v-tab
                  class="text-caption font-weight-bold"
                  :ripple="false"
                  @click="addDocumentParameterIfNeeded('step_parameter')"
                >
                  <div v-if="!stepParameter">
                    <v-icon left small color="primary">mdi-plus-circle</v-icon>Add Step
                    Parameter
                  </div>
                  <div v-else>Step Parameter</div>
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="selectedMethod.parameter_documentation.selectedTab">
                <v-tab-item>
                  <v-sheet v-if="contextDataParameter" outlined class="py-4">
                    <v-toolbar dense flat>
                      <v-checkbox
                        class="mt-0"
                        hide-details
                        v-model="contextDataParameter.canAdd"
                      ></v-checkbox>
                      <span class="text-body-2">Can Add</span>
                      <v-btn
                        icon
                        class="text-caption ml-8 font-weight-bold"
                        @click="
                          removeDocumentParameter('context_data_parameter')
                        "
                      ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
                      >
                    </v-toolbar>

                    <v-sheet class="d-flex flex-column mx-4 py-2">
                      <table>
                        <thead>
                        <tr class="text-caption grey--text text--darken-2">
                          <th class="pl-4"></th>
                          <th class="pr-4"></th>
                          <th class="px-4">Description</th>
                          <th class="px-4">Sample</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="(value, key) in documentParameterKeys"
                          :key="key"
                        >
                          <th class="pl-4">
                            <v-checkbox
                              dense
                              hide-details
                              v-model="contextDataParameter.selectedParams"
                              :value="key"
                            ></v-checkbox>
                          </th>
                          <th class="text-body-2">{{ value }}</th>
                          <td class="px-4">
                            <input-text-field
                              :filled="true"
                              v-model="contextDataParameter[key].description"
                              placeholder="Entry your text"
                              :disabled="
                                  !contextDataParameter.selectedParams.includes(
                                    key
                                  )
                                "
                            ></input-text-field>
                          </td>
                          <td class="px-4">
                            <input-text-field
                              :filled="true"
                              v-model="contextDataParameter[key].sample"
                              placeholder="Entry your text"
                              :disabled="
                                  !contextDataParameter.selectedParams.includes(
                                    key
                                  )
                                "
                            ></input-text-field>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </v-sheet>

                    <v-sheet
                      class="d-flex flex-column mx-4 mt-4 py-2"
                      v-if="contextDataParameter.selectedParams.length != 0"
                    >
                      <table>
                        <thead>
                        <tr class="text-caption grey--text text--darken-2">
                          <th
                            v-for="param in contextDataParameter.selectedParams"
                            :key="param"
                            class="px-4"
                          >
                            {{ documentParameterKeys[param] }}
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="(item, index) in contextDataParameter.list"
                          :key="index"
                        >
                          <td
                            v-for="param in contextDataParameter.selectedParams"
                            :key="param"
                            class="px-4"
                          >
                            <v-text-field
                              flat
                              hide-details
                              v-model="item[param]"
                            ></v-text-field>
                          </td>
                          <td style="width: 40px">
                            <v-btn
                              icon
                              @click="
                                  removeDocumentParameterList(
                                    'context_data_parameter',
                                    index
                                  )
                                "
                            ><v-icon small>mdi-close</v-icon></v-btn
                            >
                          </td>
                        </tr>
                        </tbody>
                        <v-btn
                          depressed
                          class="text-caption mt-4"
                          @click="
                            addDocumentParameterList('context_data_parameter')
                          "
                        >Add</v-btn
                        >
                      </table>
                    </v-sheet>
                  </v-sheet>
                </v-tab-item>
                <v-tab-item>
                  <v-sheet v-if="matchingParameter" outlined class="py-4">
                    <v-toolbar dense flat>
                      <v-checkbox
                        class="mt-0"
                        hide-details
                        v-model="matchingParameter.canAdd"
                      ></v-checkbox>
                      <span class="text-body-2">Can Add</span>
                      <v-btn
                        icon
                        class="text-caption ml-8 font-weight-bold"
                        @click="removeDocumentParameter('matching_parameter')"
                      ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
                      >
                    </v-toolbar>

                    <v-sheet class="d-flex flex-column mx-4 py-2">
                      <table>
                        <thead>
                        <tr class="text-caption grey--text text--darken-2">
                          <th class="pl-4"></th>
                          <th class="pr-4"></th>
                          <th class="px-4">Description</th>
                          <th class="px-4">Sample</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="(value, key) in documentParameterKeys"
                          :key="key"
                        >
                          <th class="pl-4">
                            <v-checkbox
                              dense
                              hide-details
                              v-model="matchingParameter.selectedParams"
                              :value="key"
                            ></v-checkbox>
                          </th>
                          <th class="text-body-2">{{ value }}</th>
                          <td class="px-4">
                            <input-text-field
                              :filled="true"
                              v-model="matchingParameter[key].description"
                              placeholder="Entry your text"
                              :disabled="
                                  !matchingParameter.selectedParams.includes(
                                    key
                                  )
                                "
                            ></input-text-field>
                          </td>
                          <td class="px-4">
                            <input-text-field
                              :filled="true"
                              v-model="matchingParameter[key].sample"
                              placeholder="Entry your text"
                              :disabled="
                                  !matchingParameter.selectedParams.includes(
                                    key
                                  )
                                "
                            ></input-text-field>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </v-sheet>

                    <v-sheet
                      class="d-flex flex-column mx-4 mt-4 py-2"
                      v-if="matchingParameter.selectedParams.length != 0"
                    >
                      <table>
                        <thead>
                        <tr class="text-caption grey--text text--darken-2">
                          <th
                            v-for="param in matchingParameter.selectedParams"
                            :key="param"
                            class="px-4"
                          >
                            {{ documentParameterKeys[param] }}
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="(item, index) in matchingParameter.list"
                          :key="index"
                        >
                          <td
                            v-for="param in matchingParameter.selectedParams"
                            :key="param"
                            class="px-4"
                          >
                            <input-text-field
                              :filled="true"
                              v-model="item[param]"
                              placeholder="Entry your text"
                            ></input-text-field>
                          </td>
                          <td style="width: 40px">
                            <v-btn
                              icon
                              @click="
                                  removeDocumentParameterList(
                                    'matching_parameter',
                                    index
                                  )
                                "
                            ><v-icon small>mdi-close</v-icon></v-btn
                            >
                          </td>
                        </tr>
                        </tbody>
                        <v-btn
                          depressed
                          class="text-caption mt-4"
                          @click="
                            addDocumentParameterList('matching_parameter')
                          "
                        >Add</v-btn
                        >
                      </table>
                    </v-sheet>
                  </v-sheet>
                </v-tab-item>
                <v-tab-item>
                  <v-sheet v-if="stepParameter" outlined class="py-4">
                    <v-toolbar dense flat>
                      <v-checkbox
                        class="mt-0"
                        hide-details
                        v-model="stepParameter.canAdd"
                      ></v-checkbox>
                      <span class="text-body-2">Can Add</span>
                      <v-btn
                        icon
                        class="text-caption ml-8 font-weight-bold"
                        @click="removeDocumentParameter('step_parameter')"
                      ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
                      >
                    </v-toolbar>

                    <v-sheet class="d-flex flex-column mx-4 py-2">
                      <table>
                        <thead>
                        <tr class="text-caption grey--text text--darken-2">
                          <th class="pl-4"></th>
                          <th class="pr-4"></th>
                          <th class="px-4">Description</th>
                          <th class="px-4">Sample</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="(value, key) in documentParameterKeys"
                          :key="key"
                        >
                          <th class="pl-4">
                            <v-checkbox
                              dense
                              hide-details
                              v-model="stepParameter.selectedParams"
                              :value="key"
                            ></v-checkbox>
                          </th>
                          <th class="text-body-2">{{ value }}</th>
                          <td class="px-4">
                            <input-text-field
                              :filled="true"
                              v-model="stepParameter[key].description"
                              placeholder="Entry your text"
                              :disabled="
                                  !stepParameter.selectedParams.includes(key)
                                "
                            ></input-text-field>
                          </td>
                          <td class="px-4">
                            <input-text-field
                              :filled="true"
                              v-model="stepParameter[key].sample"
                              placeholder="Entry your text"
                              :disabled="
                                  !stepParameter.selectedParams.includes(key)
                                "
                            ></input-text-field>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </v-sheet>

                    <v-sheet
                      class="d-flex flex-column mx-4 mt-4 py-2"
                      v-if="stepParameter.selectedParams.length != 0"
                    >
                      <table>
                        <thead>
                        <tr class="text-caption grey--text text--darken-2">
                          <th
                            v-for="param in stepParameter.selectedParams"
                            :key="param"
                            class="px-4"
                          >
                            {{ documentParameterKeys[param] }}
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="(item, index) in stepParameter.list"
                          :key="index"
                        >
                          <td
                            v-for="param in stepParameter.selectedParams"
                            :key="param"
                            class="px-4"
                          >
                            <input-text-field
                              :filled="true"
                              v-model="item[param]"
                              placeholder="Entry your text"
                            ></input-text-field>
                          </td>
                          <td style="width: 40px">
                            <v-btn
                              icon
                              @click="
                                  removeDocumentParameterList(
                                    'step_parameter',
                                    index
                                  )
                                "
                            ><v-icon small>mdi-close</v-icon></v-btn
                            >
                          </td>
                        </tr>
                        </tbody>
                        <v-btn
                          depressed
                          class="text-caption mt-4"
                          @click="addDocumentParameterList('step_parameter')"
                        >Add</v-btn
                        >
                      </table>
                    </v-sheet>
                  </v-sheet>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-text>
        <v-row class="mt-4">
          <v-col cols="6">
            <v-btn block @click="$router.push('/listener')">Back</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="primary" @click="submitListener">Edit</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog persistent v-model="showAddNewMethodModal" max-width="500">
      <v-card>
        <v-card-title>Add Method</v-card-title>
        <v-card-text>
          <input-text-field
            label="Method Name"
            :filled="true"
            v-model="newMethodName"
            placeholder="Entry your text"
          ></input-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelAddMethod">Cancel</v-btn>
          <v-btn
            @click="addMethod"
            color="primary"
            :disabled="newMethodName.length == 0"
          >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate"
import {required, minLength, sameAs} from "vuelidate/lib/validators"
import Helper from "../../js/helpers"

export default {
  name: "EditListener",
  mixins: [validationMixin],
  validations:{
    listener: {
      idplatform: {required},
      entity: {required},
      displayname: {required},
      queuename: {required},
    },
  },
  props: ['idlistener'],
  data: function () {
    return {
      platforms: [],
      listener: {
        idplatform: null,
        entity: null,
        displayname: null,
        queuename: null,
        description: null,
      },
      methods: [],

      showAddNewMethodModal: false,
      newMethodName:'',
      selectedMethodKey: 0,

      isShowAlert: false,
      alertmessage: "",
      isSuccess: false,

      platformOptions: [
        { value: 0, text: "Microsoft DynamicsCRM 365" },
        { value: 1, text: "Wufoo" },
      ],

      documentParameterKeys: {
        crm_field: 'CRM Field',
        input_field: 'Input Field',
        type: 'Type',
        operator: 'Operator',
        default: 'Default',
        lookup_entity: 'Lookup Entity'
      },

    }
  },
  created() {
    this.getPlatforms();
    this.getListener();
  },
  computed: {
    isValid() {
      return true;
    },
    methodOptions() {
      return this.methods.map((each) => {
        return { value: each.technicalname, text: each.displayname };
      });
    },
    selectedMethod() {
      return this.methods.find((each) => each.technicalname == this.selectedMethodKey);
    },
    contextDataParameter() {
      return this.selectedMethod.parameter_documentation.context_data_parameter;
    },
    matchingParameter() {
      return this.selectedMethod.parameter_documentation.matching_parameter;
    },
    stepParameter() {
      return this.selectedMethod.parameter_documentation.step_parameter;
    },
    hasParameter() {
      return this.contextDataParameter || this.matchingParameter || this.stepParameter
    }
  },
  methods: {
    getPlatforms: async function () {
      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
      }
      await this.$http.get("/api/platform", { headers }).then((response)=>{
        this.platforms =  response.data;
        return true;
      }).catch(()=>{
        return false;
      })
    },
    async getListener(){
      this.$store.commit('set', ['modalSpinnerText', 'Loading...']);
      this.$store.commit('toggle', 'showHideModalSpinner');

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken")
      }

      try {
        const response = await this.$http.get('/api/listener/' + this.idlistener, { headers })
        this.setData(response)
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit('toggle', 'showHideModalSpinner');
      }
    },

    addParam(name) {
      this.selectedMethod.parameter_documentation[name] = {
        selectedParams: [],
        crm_field: { description: null, sample: null },
        input_field: { description: null, sample: null },
        operator: { description: null, sample: null },
        default: { description: null, sample: null },
        lookup_entity: { description: null, sample: null },
        type: { description: null, sample: null },
        canAdd: true,
        list: [],
      }
    },

    addDocumentParameterIfNeeded(name) {
      if (this.selectedMethod.parameter_documentation[name]) {
        return
      }
      this.addParam(name)
    },

    addDocumentParameter(name) {
      this.addParam(name)
      switch (name) {
        case "context_data_parameter":
          this.setSelectedTab(0)
          break
        case "matching_parameter":
          this.setSelectedTab(1)
          break
        case "step_parameter":
          this.setSelectedTab(2)
          break
      }
    },
    removeDocumentParameter(name) {
      this.selectedMethod.parameter_documentation[name] = null;
      if (this.contextDataParameter) {
        this.setSelectedTab(0)
      } else if (this.matchingParameter) {
        this.setSelectedTab(1)
      } else if (this.stepParameter) {
        this.setSelectedTab(2)
      }
    },

    setSelectedTab(index) {
      this.selectedMethod.parameter_documentation.selectedTab = index
    },

    addDocumentParameterList(paramName) {
      this.selectedMethod.parameter_documentation[paramName].list.push(
        {
          crm_field: null,
          input_field: null,
          operator: null,
          default: null,
          lookup_entity: null,
          type: null,
        }
      )
    },
    removeDocumentParameterList(paramName, index) {
      this.selectedMethod.parameter_documentation[paramName].list.splice(index, 1)
    },

    setData(response) {
      const data = response.data
      this.listener.idplatform = data.idplatform
      this.listener.entity = data.entity
      this.listener.queuename = data.queuename
      this.listener.displayname = data.displayname
      this.listener.description = data.description

      const methodValues = Object.values(data.methods)
      this.methods = methodValues.map(each => {

        const doc = {
          context_data_parameter: null,
          matching_parameter: null,
          step_parameter: null,
        }

        const dataParam = each.parameter_documentation.context_data_parameter
        if (dataParam) {
          doc.context_data_parameter = this.convertParams(dataParam)
        }

        const matchingParam = each.parameter_documentation.matching_parameter
        if (matchingParam) {
          doc.matching_parameter = this.convertParams(matchingParam)
        }

        const stepParam = each.parameter_documentation.step_parameter
        if (stepParam) {
          doc.step_parameter = this.convertParams(stepParam)
        }

        return {
          technicalname: each.technicalname,
          displayname: each.displayname,
          description: each.description,
          method_argument: each.method_argument,
          parameter_documentation: doc
        }
      })
    },

    convertParams(parameter) {
      const param = {
        selectedParams: [],
        canAdd: parameter.canAdd,
        list: parameter.list
      }

      for (const key in this.documentParameterKeys) {
        if (parameter[key]) {
          param[key] = parameter[key]
          param.selectedParams.push(key)
        } else {
          param[key] = { description: null, sample: null }
        }
      }
      return param
    },

    generateListener() {

      const listener = {
        idplatform: this.listener.idplatform,
        entity: this.listener.entity,
        displayname: this.listener.displayname,
        queuename: this.listener.queuename,
        description: this.listener.description,
        is_sync: 0,
        parameter_documentation: null
      }

      const methods = {}
      this.methods.forEach(each => {

        const doc = {}

        const contextParam = each.parameter_documentation.context_data_parameter
        if (contextParam && contextParam.selectedParams.length != 0) {
          const param = {
            canAdd: contextParam.canAdd,
            list: contextParam.list
          }
          contextParam.selectedParams.forEach(p => {
            param[p] = contextParam[p]
          })
          doc.context_data_parameter = param
        }

        const matchingParam = each.parameter_documentation.matching_parameter
        if (matchingParam && matchingParam.selectedParams.length != 0) {
          const param = {
            canAdd: matchingParam.canAdd,
            list: matchingParam.list
          }
          matchingParam.selectedParams.forEach(p => {
            param[p] = matchingParam[p]
          })
          doc.matching_parameter = param
        }

        const stepParam = each.parameter_documentation.step_parameter
        if (stepParam && stepParam.selectedParams.length != 0) {
          const param = {
            canAdd: stepParam.canAdd,
            list: stepParam.list
          }
          stepParam.selectedParams.forEach(p => {
            param[p] = stepParam[p]
          })
          doc.step_parameter = param
        }

        methods[each.technicalname] = {
          displayname: each.displayname,
          technicalname: each.technicalname,
          description: each.description,
          method_argument: each.method_argument,
          parameter_documentation: doc
        }
      })

      listener.methods = JSON.stringify( methods )
      return listener
    },

    submitListener: async function () {
      if (this.$v.listener.$invalid) {
        this.$v.listener.$touch()
        return
      }

      const data = this.generateListener()

      let message = "";
      if(!data.methods){
        message =  "A method is required \n";
      }

      if (message != "") {
        this.alertmessage = message;
        setTimeout(()=>{
          this.alertmessage = "";
        },1500);
        return
      }

      this.$store.commit('set', ['modalSpinnerText', 'Creating...']);
      this.$store.commit('toggle', 'showHideModalSpinner');

      this.isShowAlert = false;
      this.alertmessage = "";

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
      }

      try {
        await this.$http.patch("/api/listener/" + this.idlistener, data, { headers })
        this.$router.push('/listener')
      } catch (error) {
        if (error.response) {
          this.isSuccess = false;
          this.isShowAlert = true;
          this.alertmessage = error.response.data.message;
        }
        console.log(error)
      } finally {
        this.$store.commit("toggle", "showHideModalSpinner");
      }
    },
    addMethod() {
      const methodKey = Helper.convertNameToKey(this.newMethodName);
      this.methods.push({
        displayname: this.newMethodName,
        technicalname: methodKey,
        description: this.newMethodName,
        method_argument: null,
        parameter_documentation: {
          context_data_parameter: null,
          matching_parameter: null,
          step_parameter: null,
          selectedTab: null
        },
      });

      this.selectedMethodKey = methodKey;
      this.newMethodName = "";
      this.showAddNewMethodModal = false;
    },
    cancelAddMethod() {
      this.newMethodName = "";
      this.showAddNewMethodModal = false
    },
    removeMethod() {
      const index = this.methods.findIndex(each => each.technicalname == this.selectedMethodKey)
      this.methods.splice(index, 1)
    }
  }
}
</script>
